<template>
  <div class="wwfooter">
      <figure class="media">
        <img class="mwwcw" src="/img/wwcopyrite.png" />
      </figure>
  </div>
</template>

<script>
export default {
    name:"Footer"
}
</script>

<style>

.mwwcw{
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  margin-bottom: 2%;
  width: 30%;

}
.wwfooter{
    background-color: #1B112D;
}
</style>