<template>
  <div class="topblock" id="topblock">
    <div class="section" style="padding-bottom: 0; padding-top: 15px">
      <div class="columns">
        <div class="column">
          <div class="mcnx" v-if="isInjected">
            <strong class="mconnection"
              >WALLET {{ walletname }}... CONNECTED!</strong
            >
          </div>
          <p class="mlargew">WICKED WRISTERS</p>
          <h2 class="msubt">
            #{{ stockLeft }}/800 SKATERS <br />
            MINTED
          </h2>
          <br />
          <div>
            <div class="field has-addons mdbtn is-centered">
              <p class="control">
                <button class="button mdbtn mmint mminus" @click="subSkater()">
                  <span class="mmath">-</span>
                </button>
              </p>
              <p class="control">
                <button class="button is-rounded mdbtn mmint" @click="letsMint()">
                  <div class="txt mbtntop">
                    MINT {{ toMint }} {{ toMint == 1 ? "SKATER" : "SKATERS" }}
                     <small class="mvsmall">{{"\n MAX 3 PER WALLET"}}</small>
                  </div>
                </button>
              </p>
              <p class="control">
                <button class="button mdbtn mmint mplus" @click="addSkater()">
                  <span class="mmath">+</span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': showSuccess }">
      <div class="modal-background"></div>
      <div class="modal-content smode">
        <img style="width: 60%" src="/img/wristerscubesuccess.gif" />
        <br />
        Congrats! You've successfully minted your Wrister! Your NFT is currently
        skating to your wallet!
        <p>
          <a target="_blank" :href="transactionlink"
            >Check this transaction on etherscan</a
          >
        </p>
        <button class="button is-rounded" @click="closesModal()">Close</button>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closesModal()"
      ></button>
    </div>
    <div class="modal" :class="{ 'is-active': showFailure }">
      <div class="modal-background"></div>
      <div class="modal-content emode">
        <img style="width: 60%" src="/img/wristerscubeerror.gif" />
        <br />
        Oh No! Looks like something went wrong while minting!
        <p>
          {{ errormsg }}
        </p>
        <button class="button is-rounded" @click="closefModal()">Close</button>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closesModal()"
      ></button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Mint",
  computed: mapState({
    isInjected: (state) => state.web3.isInjected,
    network: (state) => state.web3.networkId,
    coinbase: (state) => state.web3.coinbase,
    balance: (state) => state.web3.balance,
    walletname: (state) => state.web3.coinbase.substring(0, 5),
    web3() {
      return this.$store.state.web3;
    },
  }),
  methods: {
    addSkater() {
      if (this.toMint < 3) {
        this.toMint = this.toMint + 1;
      }
    },
    subSkater() {
      if (this.toMint > 1 ) {
        this.toMint = this.toMint - 1;
      }
    },
    async letsMint() {
      let vx = this;
      try {
        let wal = this.$store.state.web3.coinbase;
        let WristerContract = this.$store.state.contractInstance();
        const wristerPrice = await WristerContract.methods
          .wristerPrice()
          .call() * vx.toMint;
        const price = Number(wristerPrice);
        const gasAmount = await WristerContract.methods
          .mintWrister(Number(vx.toMint))
          .estimateGas({ from: wal, value: price });
        WristerContract.methods
          .mintWrister(Number(vx.toMint))
          .send({ from: wal, value: price, gas: String(gasAmount) })
          .on("transactionHash", function (hash) {
            vx.transactionHash = hash;
            vx.transactionLink = `https://${
              vx.network != 1 ? "rinkeby." : ""
            }etherscan.io/tx/${hash}`;
            console.log("transactionHash", hash);
            vx.showsModal();
          });
      } catch (e) {
        var lines = e.message.split("\n");
        if (lines.length > 1) {
          lines.splice(0, 1);
          var newtext = lines.join("\n");
          let errorblock = JSON.parse(newtext);
          if (
            errorblock.originalError.message ==
            "execution reverted: ERC721: mint to the zero address"
          ) {
            vx.errormsg =
              "Looks like you need to connect your wallet to the site";
          } else {
            vx.errormsg = errorblock.originalError.message;
          }
        } else {
          vx.errormsg = e.message;
        }

        vx.showfModal();
      }
    },
    showfModal() {
      this.showFailure = true;
    },
    closefModal() {
      this.showFailure = false;
    },

    showsModal() {
      this.showSuccess = true;
    },
    closesModal() {
      this.showSuccess = false;
      this.showFailure = false;
    },
  },
  data() {
    return {
      amount: null,
      pending: false,
      showSuccess: false,
      showFailure: false,
      transactionHash: "",
      errormsg: "",
      transactionLink: "",
      toMint: 1,
      stockLeft: 0,
    };
  },
  async mounted() {
    await this.$store.dispatch("getContractInstance");
    let WristerContract = await this.$store.state.contractInstance();
    this.stockLeft = await WristerContract.methods.totalSupply().call();
  },
  async beforeCreate() {
    let ethcon = await window.ethereum.send("eth_requestAccounts");

    if (ethcon) {
      this.$store.dispatch("registerWeb3");
    }
  },
};
</script>

<style>
.mbtntop{
  padding-bottom:0 !important;
  line-height: 0.75;
}
.mvsmall{
  font-size:10px;
  padding-bottom:3px;
  white-space: pre;
  line-height:1.5;
}
.mplus{
border-radius: 0 30px 30px 0 !important;
}
.mmath{
  font-size:30px !important;
  margin-bottom:5px;
}
.mminus{
border-radius: 30px 0 0 30px !important;
}
.mdbtn {
  font-family: balginSM;
  font-style: normal;
  color: black !important;
  margin-bottom: 5%;
  padding: 8px 20px !important;
  transition: 1s !important;
}
.mmint {
   background-color:#7ddd52 !important ;
  font-size: 20px !important;
  margin: 0 auto;
}
.mconnection {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: proxima-nova, sans-serif;
  color: white !important;
  font-size: 12px;
  background-color: #7ba363;
  border-radius: 20px;
}
.mcnx {
  margin-top: 5px;
  margin-bottom: 25px;
}
.smode {
  background-color: #7ddd52;
  opacity: 1 !important;
  border: 1px solid black;
  border-radius: 12px;
  font-family: proxima-nova, sans-serif;
  color: black !important;
  font-size: 25px;
  padding-bottom: 10px;
}
.emode {
  background-color: #e63d3d;
  opacity: 1 !important;
  border: 1px solid black;
  border-radius: 12px;
  font-family: proxima-nova, sans-serif;
  color: black !important;
  font-size: 25px;
  padding-bottom: 10px;
}
.mdicon {
  padding-right: 8px;
  padding-bottom: 0px;
  margin-top: 5px;
  height: 20px;
  margin-bottom: 2%;
  margin-right: 2%;
}

.coverman {
  display: flex;
  width: 60%;
  margin-left: 55%;
  margin-top: -60%;
}

.mlargew {
  font-family: POPFUN;
  font-style: normal;
  color: white !important;
  line-height: 0.8;
  text-align: center;
  text-shadow: 2px 2px black;
  font-size: 5em;
  -webkit-text-stroke: 2px black;
}
.msubt {
  font-family: balginBold;
  font-size: 12px;
  letter-spacing: 5px;
  color: black;
}

.topblock {
  background-color: #f8c7dd;
}
.dbtn:hover {
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.low {
  margin-top: auto;
  padding-bottom: 0.9% !important;
  float: left;
}
.low img {
  float: left !important;
}
.buff {
  padding-bottom: 7% !important;
}
</style>