<template>
  <div class="mtopspacer">
      <img src="/img/ww.png" />
  </div>
  <Mint></Mint>
  <About></About>
  <Rarity></Rarity>
  <Rollout></Rollout>
  <Simulation></Simulation>
  <Trailer></Trailer>
  <FAQ></FAQ>
  <Team></Team>
  <Footer></Footer>
</template>

<script>
import Mint from "./mobile/Mint";
import About from "./mobile/About";
import Rarity from "./mobile/Rarity";
import Rollout from "./mobile/Rollout";
import Simulation from "./mobile/Simulation";
import Trailer from "./mobile/Trailer";
import FAQ from "./mobile/FAQ";
import Team from "./mobile/Team";
import Footer from "./mobile/Footer";
export default {
  name: "App",
  components: {
    Mint,
    About,
    Rarity,
    Rollout,
    Simulation,
    Trailer,
    FAQ,
    Team,
    Footer
  },
  data() {
    return {
      hovsim: false,
    };
  },
};
</script>

<style>
@import url("https://use.typekit.net/vdg5puh.css");
@font-face {
  font-family: "POPFUN";
  src: url("/fonts/POPFUN.eot"); /* IE9 Compat Modes */
  src: url("/fonts/POPFUN.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/POPFUN.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/POPFUN.woff2") format("woff2"),
    /* Modern Browsers */ url("/fonts/POPFUN.ttf") format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "balgin";
  src: url("/fonts/BalginText-Regular.otf") format("OpenType");
}

@font-face {
  font-family: "balginSM";
  src: url("/fonts/Balgin-BoldSmCondensed.otf") format("OpenType");
}

@font-face {
  font-family: "balginBold";
  src: url("/fonts/BalginText-Bold.otf") format("OpenType");
}

@font-face {
  font-family: "extrude";
  src: url("/fonts/POPFUN_Extrude.ttf") format("TrueType");
  font-weight: bolder;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html, body {
  overflow-x: hidden;
}

body {
  position: relative
}

.mtopspacer {
  margin-top: 2%;
}

.navbar-item {
  color: black !important;
}

.spacey {
  font-size: 12px;
  font-family: balginBold;
  letter-spacing: 2px;
  margin-right: auto;
  margin-left: auto;
}

.holo {
  background: url("/img/holobkrd.png") no-repeat top left;
  background-size: cover;
  font-weight: 900;
  font-style: normal;
  color: white !important;
}

.countdown {
  background-color: #2fa0f9;
  color: white;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  letter-spacing: 1.2px;
}

.redblocker {
  background-color: #7b61ae;
  height: 70px;
}

.blueblocker {
  background-color: #2fa0f9;
  height: 70px;
}

.navbar .navbar-brand {
  text-align: center;
  display: block;
}
</style>
