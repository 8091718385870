<template>
  <div class="wwfooter">
    <div class="container">
      <figure class="media">
        <img class="wwcw" src="/img/wwcopyrite.png" />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
    name:"Footer"
}
</script>

<style>

.wwcw{
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  margin-bottom: 2%;
  width: 15%;

}
.wwfooter{
    background-color: #1B112D;
}
</style>