<template>
  <div class="trailer" id="trailer">
    <div class="section">
      <div class="container">
        <h1 class="trailerheading">TRAILER</h1>
        <video
          src="vid/FINALTRAILER.mp4"
          controls="controls"
          height="300px"
          poster="img/blur.png"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Trailer",
};
</script>

<style>
.trailer {
  background-color: #BE2929;
  height: 900px;
}
.trailerheading {
  color: white;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 7.5em;
  text-align: center;
  letter-spacing: 9.07px;

}
</style>