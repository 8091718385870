<template>
  <div class="rollout" id="roadmap">
    <div class="section">

      <div class="container">
        <div class="columns">
          <div class="column is-one-third">
            <video id="stadium" class="stadium" preload="auto">
              <source src="/img/stadium.webm" type="video/webm">
            </video>
          </div>
          <div class="column is-one-third">
            <h1 class="rolloutheading">GAMEPLAN</h1>
          </div>
        </div>
        <!-- Main container -->
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <div id="dates-text01">01</div>
              <div id="dates-text-date01">november 18</div>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="rolloutblock01">
                <div class="level-item">
                  <div class="columns">
                    <div class="column is-half txt-left">
                      800 NFTS MINTING LIVE!
                    </div>
                    <div class="vl"></div>
                    <div class="column txt-right down">
                      You're a First Rounder! The draft has started and 32 teams
                      are looking to fill their rosters with you!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <!-- Main container -->
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <div id="dates-text">02</div>
              <div id="dates-text-date">november 20</div>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="rolloutblock">
                <div class="level-item">
                  <div class="columns">
                    <div class="column is-half txt-left">
                      NFT REVEAL
                    </div>
                    <div class="vl"></div>
                    <div class="column txt-right">
                    You're Wrister is out of the dressing room and ready to show off that luscious lettuce.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <!-- Main container -->
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <div id="dates-text">03</div>
              <div id="dates-text-date">january 2022</div>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="rolloutblock03">
                <div class="level-item">
                  <div class="columns">
                    <div class="column is-half txt-left">PLAYER DATABASE</div>
                    <div class="vl"></div>
                    <div class="column txt-right">
                      You've minted your Wicked Wrister and today is the day you
                      find out if their dirty dangles can live up to their sick
                      flow. All players attributes revealed and see how the teams
                      stack up going into SEASON 1!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <div id="dates-text0506">04</div>
              <div id="dates-text-date0506">Q1 2022</div>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="rolloutblock0506">
                <div class="level-item">
                  <div class="columns">
                    <div class="column is-half txt-left">
                      GAME <br/> SIM <br/> LIVE!!
                    </div>
                    <div class="vl"></div>
                    <div class="column txt-right">
Your players are warmed up and ready to hit the ice as our DAILY simulation starts to take place and you can start your journey to hoisting the Ivy Rose trophy! or personal stats; you know... whatever you're into.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <!-- Main container -->
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <div id="dates-text0506">05</div>
              <div id="dates-text-date0506">Q1 2022</div>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="rolloutblock0506">
                <div class="level-item">
                  <div class="columns">
                    <div class="column is-half txt-left">
                      1,200 STAGED NFTS MINTING
                    </div>
                    <div class="vl"></div>
                    <div class="column txt-right">
                      3 seasons after the Game SIM has launched we will start to
                      mint 200 new Draft Picks month by month until we hit 2,000
                      total skaters!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Rollout",
  mounted() {
    const vid = document.getElementById("stadium")
      window.addEventListener('scroll', function () {
        vid.currentTime = ((window.pageYOffset/150)%8);
      });
  },
};
</script>



<style>
.txt-left {
  margin-top: auto;
  margin-bottom: auto;
  font-family: proxima-nova, sans-serif;
  color: black;
  font-weight: 600;
  font-style: normal;
  font-size: 2.5em;
  text-align: center;
  letter-spacing: 1.15px;
  /* margin-top: 5%; */
}
.txt-right {
  margin-top: auto;
  margin-bottom: auto;
  font-family: proxima-nova, sans-serif;
  color: black;
  font-weight: 500;
  font-style: normal;
  font-size: 1.2em;
  text-align: center;
  letter-spacing: 0.51px;
  line-height: 2;
}
.rolloutblock {
  background-color: white;
  padding: 4%;
  border: white;
  border-radius: 53px;
  height: 285px;
  width: 725px;
  margin-left: -21%;
  margin-bottom: 15%;
}

.rolloutblock01 {
  background-color: white;
  padding: 3%;
  border: white;
  border-radius: 53px;
  height: 270px;
  width: 725px;
  margin-left: -15%;
  margin-bottom: 15%;
}
.rolloutblock03 {
  background-color: white;
  padding: 4%;
  border: white;
  border-radius: 53px;
  height: 285px;
  width: 725px;
  margin-left: -19%;
  margin-bottom: 15%;
}
.rolloutblock04 {
  background-color: white;
  padding: 4%;
  border: white;
  border-radius: 53px;
  height: 285px;
  width: 725px;
  margin-left: -15%;
  margin-bottom: 15%;
}
.rolloutblock0506 {
  background-color: white;
  padding: 3%;
  border: white;
  border-radius: 53px;
  height: 290px;
  width: 725px;
  margin-bottom: 15%;
    margin-left: 2%;
}
.vl {
  border-left: 6px solid #ff5d5d;
  height: 13em;
  margin-top: 3%;
}
#dates-text01 {
  color: black;
  font-family: POPFUN;
  font-weight: 500;
  font-style: normal;
  font-size: 271px;
  text-align: left;
  letter-spacing: 9.57px;
  margin-top: -15%;
  margin-left: -13%;
}
#dates-text-date01 {
  color: black;
  font-family: POPFUN;
  font-weight: 500;
  font-style: normal;
  font-size:65px;
  text-align: left;
  letter-spacing: 1.3px;
  transform: rotate(270deg);
  margin-left: -11%;
  margin-bottom: 30%;
}
#dates-text {
  color: black;
  font-family: POPFUN;
  font-weight: 500;
  font-style: normal;
  font-size: 271px;
  text-align: left;
  letter-spacing: 9.57px;
  margin-top: -15%;
  margin-left: -17%;
}
#dates-text-date {
  color: black;
  font-family: POPFUN;
  font-weight: 500;
  font-style: normal;
  font-size:65px;
  text-align: left;
  letter-spacing: 1.3px;
  transform: rotate(270deg);
  margin-left: -15%;
  margin-bottom: 30%;
}
#dates-text0506 {
  color: black;
  font-family: POPFUN;
  font-weight: 500;
  font-style: normal;
  font-size: 271px;
  text-align: left;
  letter-spacing: 9.57px;
  margin-top: -15%;
  margin-left: -6.9%;
}
#dates-text-date0506 {
  color: black;
  font-family: POPFUN;
  font-weight: 500;
  font-style: normal;
  font-size:65px;
  text-align: left;
  letter-spacing: 1.3px;
  transform: rotate(270deg);
  margin-left: -9%;
  margin-bottom: 30%;
}
.rollout {
  background-color: #f8c7dd;
  height: 2500px;
}
.rolloutheading {
  color: black;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 7.5em;
  text-align: center;
  letter-spacing: 9.07px;
  margin-bottom: 5%;
  margin-top: -15%;
}
.stadium {
  display: flex;
  margin-left: -40%;
  margin-top: -30%;
}
</style>