<template>
<div class="rarity" id="rarity">
    <div class="section" style="padding-bottom: 0">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="rarityheading">
              Generative Art
            </div>
            <div class="raritytext">
              Your skaters are generated with 140 hairstyles, 140 faces and up to 10 different traits. The individual assets that can be selected are weighted with different rarities and are able to produce over 1 BILLION combinations
            </div>
          </div>
          <div class="column is-vcentered">
            <figure class="image is-226x227">
              <img src="/img/24frameanimationbig.gif" class="rarityimg">
            </figure>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="rarityheading2">
              Dynamic NFT
            </div>
            <div class="raritytext">
              Your NFT will be dynamic and updated once a month! Your players progression in the SIM can catapult your player to higher overalls therefore changing their background! Your jersey may update as well if you've been traded!
            </div>
          </div>
          <div class="column is-vcentered">
            <figure class="image is-256x256">
              <img src="/img/upgradegifnotextbig.gif" class="rarityimg">
            </figure>
          </div>
        </div>
        <p class="bottomtext">YOUR SKATER'S BEARD AND ACCESSORIES ARE CURATED TO MAKE SURE IT WILL FIT YOUR SKATERS FACE SHAPE!</p>
      </div>
    </div>
</div>
</template>

<script>
export default {
  name: "Rarity",
};
</script>

<style>
.rarity {
  background-color: #3A85FF;
  padding-top: 3%;
  padding-bottom: 3%;
}


.rarityheading {
  color: white;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 4.5em;
  text-align: left;
  letter-spacing: 4.5px;
}

.rarityheading2 {
  color: white;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 4.5em;
  text-align: left;
  letter-spacing: 4.5px;
}

.raritytitle {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5em;
  text-align: center;
}
.raritytext {
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5em;
  text-align: left;
  padding-bottom: 3%;
  margin-right: -15%;
  margin-top: -5%;
  line-height: 1.5;
}

.bottomtext {
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 1.5em;
  padding-bottom: 3%;
  padding-left: 15%;
  padding-right: 15%;
}


.raritynumber {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 5.83px;
}

.rarityimg {
  padding-bottom: 15%;
  max-width: 60%;
  float: right;
}

.pfp {
  border-radius: 50%;
  width: 60%;
}
</style>