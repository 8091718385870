<template>
  <div class="team" id="team">
    <div class="section">
      <div class="container">
        <h1 class="teamheading">TEAM</h1>
        <div class="columns">
          <div class="column">
            <div class="blx">
              <figure class="media-right">
                <img class="pfp" src="/img/jaypfp.png" />
              </figure>
            </div>
          </div>
          <div class="column">
            <div class="blx">
                <div class="media-content">
                  <h2 class="teamsuper">WOLVESATMYDOOR</h2>
                  <h3 class="teamtitle">CREATOR / DESIGNER / PIXEL ARTIST</h3>
                  <h4 class="teamsocial"><a class="sociallink" target="blank" href="https://twitter.com/wlvsatmydoor"><img class="twt" src="/img/twitter-icon.png">@wlvsatmydoor</a></h4>
                </div>
            </div>
          </div>
          <div class="column">
            <div class="blx">
              <figure class="media-right">
                <img class="pfp" src="/img/bdubs.png" />
              </figure>
            </div>
          </div>
          <div class="column">
              <div class="blx">
                <div class="media-content">
                  <h2 class="teamsuper">BDOUBLEO100</h2>
                  <h3 class="teamtitle">ARTIST</h3>
                  <h4 class="teamsocial"><a class="sociallink" target="blank" href="https://twitter.com/BdoubleO100"><img class="twt" src="/img/twitter-icon.png">@bdoubleo100</a></h4>
                </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="blx">
              <figure class="media-right">
                <img class="pfp" src="/img/gurglepfp.png" />
              </figure>
            </div>
          </div>
          <div class="column">
            <div class="media-content">
              <h2 class="teamsuper">GURGLESWAMP</h2>
              <h3 class="teamtitle">DEVELOPER</h3>
              <h4 class="teamsocial"><a class="sociallink" target="blank" href="https://twitter.com/gurgleswamp"><img class="twt" src="/img/twitter-icon.png">@gurgleswamp</a></h4>
            </div>
          </div>

          <div class="column">
            <div class="blx">
              <figure class="media-right">
                <img class="pfp" src="/img/jpfp.png" />
              </figure>
            </div>
          </div>
          <div class="column">
            <div class="media-content">
              <h2 class="teamsuper">JAYAREUU</h2>
              <h3 class="teamtitle">DEVELOPER</h3>
              <h4 class="teamsocial"><a class="sociallink" target="blank" href="https://twitter.com/jayareuu"><img class="twt" src="/img/twitter-icon.png">@jayareuu</a></h4>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="blx">
              <figure class="media-right">
                <img class="pfp" src="/img/mikepfp.png" />
              </figure>
            </div>
          </div>
          <div class="column">
            <div class="blx">
              <div class="media-content">
                <h2 class="teamsuper">DECOY</h2>
                <h3 class="teamtitle">DEVELOPER</h3>
                <h4 class="teamsocial"><a class="sociallink" target="blank" href="https://twitter.com/decoycodes"><img class="twt" src="/img/twitter-icon.png">@DecoyCodes</a></h4>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="blx">
              <figure class="media-right">
                <img class="pfp" src="/img/vanpapfp.png" />
              </figure>
            </div>
          </div>
          <div class="column">
            <div class="blx">
                <div class="media-content">
                  <h2 class="teamsuper">VANPA</h2>
                  <h3 class="teamtitle">NFT THINK TANK</h3>
                  <h4 class="teamsocial"><img class="twt" target="blank" src="/img/twitter-icon.png"><a class="sociallink" href="https://twitter.com/vanpa_0x">@vanpa_0x</a></h4>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
};
</script>

<style>

.team {
  background-color: #1B112D;
}

.teamheading {
  letter-spacing: 9.07px;
  color: white !important;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 7.5em;
}

.teamsuper {
  font-family: POPFUN;
  font-style: normal;
  font-size: 2.5em;
  padding-top: 1%;
  color: white;
  letter-spacing: 2px;
  margin-left: -25%;
  margin-bottom: -7%;
  text-align: left;
}

.teamtitle {
  font-family: proxima-nova, sans-serif;
  font-weight:500;
  font-style: normal;
  font-size: 1em;
  color: white !important;
  margin-left: -25%;
  margin-bottom: -6%;
  text-align: left;
}

.teamsocial {
  font-family: proxima-nova, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  color: white !important;
  margin-left: -25%;
  text-align: left;

}

.sociallink {
  color: white;
  font-size: .7em;
}

.sociallink:hover {
  color: #1DA1F2;
  font-size: .7em;
}

.pfp {
  border-radius: 50%;
  width: 40%;
  margin-right: 1%;
  margin-top:5%;
}

.blx {
  margin-bottom: 2%;
}

.twt {
  width: 4.5%;
  margin-right: 1%;
}

</style>