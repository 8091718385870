<template>
  <div class="about" id="about">
    <swiper
    :modules="modules"
    :autoplay='{
    "delay": 1000,
    "disableOnInteraction": false
  }'
    :slides-per-view="10"
    :space-between="38"
    :breakpoints="swiperOpts.breakpoints"
    :loop="true"
  >
    <swiper-slide v-for="slide in slides" :key="slide.id"><img class="slimg" :src="slide.image" /></swiper-slide>
    ...
  </swiper>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-vcentered">
            <p class="abouttext slimtop">
              <strong class="has-text-white">You</strong> have skated your way
              into the Metaverse and are ready to mint your way into the
              <strong class="has-text-white">Blockchain Hockey League</strong>!
            </p>
            <div class="field bchl">
              <div class="field is-inline-block-desktop">
                <figure class="responsive">
                  <img src="/img/bchllogo.png" />
                </figure>
              </div>
            </div>
            <p class="abouttext slimbot">
              There is only <strong class="has-text-white">ONE</strong> league
              and it is filled up with
              <strong class="has-text-white">YOUR</strong>
              minted NFTS. Your Wrister will be randomly drafted to 1 of 32
              custom teams and also be randomly given 5 attributes to make up
              your players Overall Rating which will be reflected in the
              background color of your skater!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, {Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.min.css';
SwiperCore.use([Autoplay]);
export default {
  data() {
    return {
      isMobile: false,
      modules:[Autoplay],
      swiperOpts: {
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 30
          },
          800: {
            slidesPerView: 5,
            spaceBetween: 30
          },
          960: {
            slidesPerView: 6,
            spaceBetween: 30
          },
          1120: {
            slidesPerView: 7,
            spaceBetween: 30
          },
          1280: {
            slidesPerView: 8,
            spaceBetween: 30
          },
          1440: {
            slidesPerView: 9,
            spaceBetween: 30
          }
        }
      },
      slides: [
        {image:"/img/image (1).png",id:1},
        {image:"/img/image (2).png",id:2},
        {image:"/img/image (3).png",id:3},
        {image:"/img/image (4).png",id:4},
        {image:"/img/image (5).png",id:5},
        {image:"/img/image (6).png",id:6},
        {image:"/img/image (7).png",id:9},
        {image:"/img/image (8).png",id:10},
        {image:"/img/image (9).png",id:15},
        {image:"/img/image (10).png",id:19},
        {image:"/img/image (11).png",id:21},
        {image:"/img/image (12).png",id:23},
        {image:"/img/image (13).png",id:24},
        {image:"/img/image (14).png",id:25},
        {image:"/img/image (15).png",id:26},
        {image:"/img/image (16).png",id:27},
        {image:"/img/image (17).png",id:28},
        {image:"/img/image (18).png",id:222},
        {image:"/img/image (19).png",id:292},
        {image:"/img/image (20).png",id:295},
        {image:"/img/image (21).png",id:294},
        {image:"/img/image (22).png",id:229},
        {image:"/img/image (23).png",id:219}
      ],
    };
  },
 components: {
      Swiper,
      SwiperSlide,
    }
};
</script>
<style>
.responsive {
  max-width: 100%;
  height:auto;
  width:300px;
}
.bchl {
  padding-top: 2%;
}
.about {
  padding-top: 2%;
  background-color: #1E1D1F;
}
.slimtop {
  margin-left: 7%;
  margin-right: 7%;
}
.slimg{
  width:224px;
}
.abouttext {
  font-family: proxima-nova, sans-serif;
  color: white !important;
  font-weight: 200;
  font-style: normal;
  font-size: 1.5em;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.81px;
}
</style>