<template>
  <div class="mrollout" id="roadmap">
    <div class="section">
      <video id="stadium" class="mstadium" preload="auto">
        <source src="/img/stadium.webm" type="video/webm">
      </video>
      <h1 class="mrolloutheading">GAMEPLAN</h1>
      <div class="mrolloutblock">
        <div id="mdates-text">01</div>
        <div class="mvl"></div>
        <div class="mtxt-right">800 NFTS MINTING LIVE!</div>
      </div>
      <div class="mfloatingtxt">
        You're a First Rounder! The draft has started and 32 teams are looking to fill their rosters with you! (NOVEMBER 18)
      </div>

      <div class="mrolloutblock">
        <div id="mdates-text">02</div>
        <div class="mvl"></div>
        <div class="mtxt-right">NFT REVEAL</div>
      </div>
      <div class="mfloatingtxt">
        Your Wrister is out of the dressing room and ready to show off that luscious lettuce. (NOVEMBER 20)
      </div>

      <div class="mrolloutblock">
        <div id="mdates-text">03</div>
        <div class="mvl"></div>
        <div class="mtxt-right">PLAYER DATABASE</div>
      </div>
      <div class="mfloatingtxt">
        You've minted your Wicked Wrister and today is the day you
        find out if their dirty dangles can live up to their sick
        flow. All players attributes revealed and see how the teams
        stack up going into SEASON 1! (JANUARY 2022)
      </div>

      <div class="mrolloutblock">
        <div id="mdates-text">04</div>
        <div class="mvl"></div>
        <div class="mtxt-right">GAME <br/> SIM <br/> LIVE!!</div>
      </div>
      <div class="mfloatingtxt">
        Your players are warmed up and ready to 
        hit the ice as our DAILY simulation starts to take place and you can start your 
        journey to hoisting the Ivy Rose trophy! or personal stats; 
        you know... whatever you're into. (Q1 2022)
      </div>

      <div class="mrolloutblock">
        <div id="mdates-text">05</div>
        <div class="mvl"></div>
        <div class="mtxt-right">1,200 STAGED NFTS MINTING</div>
      </div>
      <div class="mfloatingtxt">
          3 seasons after the Game SIM has launched we will start to
          mint 200 new Draft Picks month by month until we hit 2,000
          total skaters! (Q1 2022)
      </div>
    </div>
    </div>
</template>


<script>
export default {
  name: "Rollout",
  mounted() {
    const vid = document.getElementById("stadium")
      window.addEventListener('scroll', function () {
        vid.currentTime = ((window.pageYOffset)/100%8);
      });
  },
};
</script>



<style>

.mtxt-right {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  font-family: proxima-nova, sans-serif;
  color: black;
  font-weight: 1000;
  font-style: normal;
  font-size: 1em;
  text-align: center;
  letter-spacing: 0.51px;
}
.mrolloutblock {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 4%;
  border: white;
  border-radius: 13px;
  height: 95px;
  width: 257px;
  margin-bottom: 5%;
  margin-top: 15%;
}

.mvl {
  border-left: 2px solid #ff5d5d;
  height: 4em;
  margin-left: 10%;
}
#mdates-text {
  margin-top: -11%;
  margin-left: 5%;
  color: black;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 89px;
  letter-spacing: 2.57px;
}
.mrollout {
  background-color: #f8c7dd;
  height: 1150px;
}
.mrolloutheading {
  color: white;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 2.2em;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: -12%;
}
.mstadium {
  display: flex;
  width:50%;
  margin-top: -20%;
  margin-left: auto;
  margin-right: auto;
}

.mfloatingtxt {
  font-family: proxima-nova, sans-serif;
  color: black;
  font-size: 12px;
  margin-left: 6%;
  margin-right: 6%;
  margin-bottom: -10%;
}

</style>