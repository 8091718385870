<template>
  <div class="mtrailer" id="trailer">
    <div class="section">
        <h1 class="mtrailerheading">TRAILER</h1>
        <video
          src="vid/FINALTRAILER.mp4"
          controls
          height="300px"
          poster="img/blur.png"
        ></video>
      </div>
  </div>
</template>

<script>
export default {
  name: "Trailer",
};
</script>

<style>
.mtrailer {
  background-color: #BE2929;
}
.mtrailerheading {
  color: white;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 4.5em;
  text-align: center;
  letter-spacing: 4.5px;

}
</style>