<template>
  <div class="simulation" id="simulation">
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-one-third">
            <img class="game-box" src="/img/WWPRODUCTBOXsingle.png" />
          </div>
          <div class="column is-one-third">
            <h1 class="simulationheading">GAME SIM</h1>
            </div>
        </div>

        <div class="bg-img">
          <h1 class="simulationheading2 ">LIVE TEXT SIMULATION</h1>
        </div>
        <div class="columns">
          <div class="column is-vcentered">
            <p class="simulationtext">
              We are currently working on and developing a very intuitive and
              fun user-experience webapp that will run our simulation where YOUR
              nft's are the stars! This is the meat and potatoes of our utility
              for this project; this is just the game sim screen, but we will
              have a dashboard where you will find everything from current
              season schedule, hopping into an ongoing sim, all the way to current
              and past player stats along with transactions and legacy areas
              like the history books, hall of fame and trophy cabinets!
            </p>

            <p class="simulationtext2">
              Each of the <strong class="has-text-white">32 TEAMS</strong> will
              have <strong class="has-text-white">3 GAMES</strong> simulated
              everyday spread throughout. This will result in
              <strong class="has-text-white">1 SEASON</strong> of
              <strong class="has-text-white">60 GAMES</strong> taking
              <strong class="has-text-white">1 MONTH</strong> in real time!
            </p>
            <p class="simulationtext3">
              Every team is made up of  <strong class="has-text-white">25 SKATERS</strong> (your nfts)
            </p>
            <p class="simulationtext4">
              • <strong class="has-text-white">12</strong> OFFENSIVE<br />
              • <strong class="has-text-white">6</strong> DEFENSIVE<br />
              • <strong class="has-text-white">2</strong> GOALIES<br />
              • <strong class="has-text-white">5</strong> BENCH<br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Simulation",
};
</script>

<style>

.sim-img {
  margin-bottom: 20%;

}

.simulation {
  background-color: #7b61ae;
  height: 2050px;
}
.simulationheading {
  color: white;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 7.5em;
  text-align: center;
  letter-spacing: 9.07px;
  margin-left: -30%;
  margin-right: -30%;
}
.simulationheading2 {
  padding-top: 60%;
  color: white;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 9.5em;
  text-align: center;
  letter-spacing: 9.07px;
  text-shadow: black 1px 0 10px;
}
.bg-img {
  background-image: url("/img/SIM3.png");
  width: 1000px;
  margin-left: auto;
  margin-right: auto;

}

.simulationtext {
  font-family: proxima-nova, sans-serif;
  color: white !important;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5em;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.81px;
}
.simulationtext2 {
  font-family: proxima-nova, sans-serif;
  color: white !important;
  font-weight: 300;
  font-style: normal;
  font-size: 30px;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.81px;
  margin-top: 5%;
}
.simulationtext3 {
  font-family: proxima-nova, sans-serif;
  color: white !important;
  font-weight: 300;
  font-style: normal;
  font-size: 30px;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.81px;
  margin-top: 5%;
}
.simulationtext4 {
  font-family: proxima-nova, sans-serif;
  color: white !important;
  font-weight: 300;
  font-style: normal;
  font-size: 30px;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.81px;
}

.game-box {
  margin-left: -80%;
  margin-top: -65%;
  max-width: 130%;
}
</style>