import { createApp } from 'vue'
import App from './App.vue'
import "bulma/css/bulma.min.css";
import VueSmoothScroll from 'vue3-smooth-scroll';
import { store } from './store/index.js'



const app = createApp(App);
app.use(VueSmoothScroll);
app.use(store);
app.mount('#app');
  