<template>
<Mobile v-if="isMobile"></Mobile>
<Desktop v-else></Desktop>
</template>

<script>
import Mobile from "./components/Mobile.vue";
import Desktop from "./components/Desktop.vue";
export default {
  name: "App",
  components: {
    Desktop,
    Mobile
  },
  data() {
    return {
      hovsim: false,
      isMobile: false,
    };
  },
  methods: {
    detectWidth() {
      this.isMobile = screen.width < 768;
    },
  },
  created() {
    this.detectWidth();
    window.onresize = this.detectWidth;
  },
};
</script>

<style>
.container {
  width: 1000px !important;
}
@import url("https://use.typekit.net/vdg5puh.css");
@font-face {
  font-family: "POPFUN";
  src: url("/fonts/POPFUN.eot"); /* IE9 Compat Modes */
  src: url("/fonts/POPFUN.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/POPFUN.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/POPFUN.woff2") format("woff2"),
    /* Modern Browsers */ url("/fonts/POPFUN.ttf") format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "balgin";
  src: url("/fonts/BalginText-Regular.otf") format("OpenType");
}

@font-face {
  font-family: "balginSM";
  src: url("/fonts/Balgin-BoldSmCondensed.otf") format("OpenType");
}

@font-face {
  font-family: "balginBold";
  src: url("/fonts/BalginText-Bold.otf") format("OpenType");
}

@font-face {
  font-family: "extrude";
  src: url("/fonts/POPFUN_Extrude.ttf") format("TrueType");
  font-weight: bolder;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.logoimg {
  max-height: 65px !important;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 30%;
}

.navbar-item {
  color: black !important;
}

.spacey {
  font-size: 12px;
  padding-left: 25%;
  padding-right: 25%;
  font-family: balginBold;
  letter-spacing: 2px;
  margin-right: auto;
  margin-left: auto;
}
.holo {
  background: url("/img/holobkrd.png") no-repeat top left;
  background-size: cover;
  font-weight: 900;
  font-style: normal;
  color: white !important;
}

.countdown {
  background-color: #2fa0f9;
  color: white;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  letter-spacing: 1.2px;
}

.navbar .navbar-brand {
  text-align: center;
  display: block;
  width: 30%;
}

.navbar-item:hover {
  color: #FA8AD6 !important;
}
</style>
