<template>
    <nav
    class="navbar is-fixed-top spacey"
    role="navigation"
    aria-label="main navigation"
  > 
    <div class="navbar-menu">
         <div class="move-ww">
      <a class="navbar-item" href="#topblock" v-smooth-scroll
        ><img src="/img/ww.png" class="logoimg"
      /></a>
    </div>
     </div>
    <div class="navbar-menu">
        <div class="move-components"><a class="navbar-item" href="#about" v-smooth-scroll> ABOUT </a></div>
        <div class="move-components"><a class="navbar-item" href="#rarity" v-smooth-scroll> RARITY </a></div>
        <div class="move-components"><a class="navbar-item" href="#roadmap" v-smooth-scroll> GAME PLAN </a></div>
        <div class="move-components"><a class="navbar-item" href="#simulation" v-smooth-scroll> SIMULATION </a></div>
        <div class="move-components"><a class="navbar-item" href="#trailer" v-smooth-scroll> TRAILER </a></div>
        <div class="move-components"><a class="navbar-item" href="#faq" v-smooth-scroll> FAQ </a></div>
        <div class="move-components"><a class="navbar-item" href="#team" v-smooth-scroll> TEAM </a></div>
        <div class="move-socials">
        <a class="navbar-item" target="blank" href="https://discord.gg/pdKhvZ7Txk">
          <img src="/img/svg-discord.svg" class="sicon" />
        </a></div>
        <div class="move-socials">
        <a class="navbar-item" target="blank" href="https://twitter.com/Wicked_Wristers">
          <img src="/img/svg-twitter.svg" class="sicon" />
        </a></div>
        <div class="move-socials">
        <a class="navbar-item" target="blank" href="https://opensea.io/collection/wickedwristers">
          <img src="/img/svg-opensea.svg" class="sicon" />
        </a></div>
        

    </div>
  </nav>
  <Mint></Mint>
  <About></About>
  <Rarity></Rarity>
  <Rollout></Rollout>
  <Simulation></Simulation>
  <Trailer></Trailer>
  <FAQ></FAQ>
  <Team></Team>
  <Footer></Footer>
</template>

<script>
import Mint from "./Mint";
import About from "./About";
import Rarity from "./Rarity";
import Rollout from "./Rollout";
import Simulation from "./Simulation";
import Trailer from "./Trailer";
import FAQ from "./FAQ";
import Team from "./Team";
import Footer from "./Footer";
export default {
  name: "App",
  components: {
    Mint,
    About,
    Rarity,
    Rollout,
    Simulation,
    Trailer,
    FAQ,
    Team,
    Footer
  },
  data() {
    return {
      hovsim: false,
    };
  },
};
</script>

<style>
.container {
  width: 1000px !important;
}
@import url("https://use.typekit.net/vdg5puh.css");
@font-face {
  font-family: "POPFUN";
  src: url("/fonts/POPFUN.eot"); /* IE9 Compat Modes */
  src: url("/fonts/POPFUN.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/POPFUN.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/POPFUN.woff2") format("woff2"),
    /* Modern Browsers */ url("/fonts/POPFUN.ttf") format("truetype"); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "balgin";
  src: url("/fonts/BalginText-Regular.otf") format("OpenType");
}

@font-face {
  font-family: "balginSM";
  src: url("/fonts/Balgin-BoldSmCondensed.otf") format("OpenType");
}

@font-face {
  font-family: "balginBold";
  src: url("/fonts/BalginText-Bold.otf") format("OpenType");
}

@font-face {
  font-family: "extrude";
  src: url("/fonts/POPFUN_Extrude.ttf") format("TrueType");
  font-weight: bolder;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.logoimg {
  max-height: 65px !important;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 30%;
}

.move-ww{
  margin-left: auto;
  margin-right: 5%;
}

.move-components{
  margin-top: 1.5%;
  margin-left: .40%;
}
.move-socials{
  margin-top: 1%;
  margin-left: .10%;
}



.navbar-item {
  color: black !important;
}

.spacey {
  font-size: 12px;
  padding-left: 25%;
  padding-right: 25%;
  font-family: balginBold;
  letter-spacing: 2px;
  margin-right: auto;
  margin-left: auto;
}
.holo {
  background: url("/img/holobkrd.png") no-repeat top left;
  background-size: cover;
  font-weight: 900;
  font-style: normal;
  color: white !important;
}

.countdown {
  background-color: #2fa0f9;
  color: white;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  letter-spacing: 1.2px;
}

.navbar .navbar-brand {
  text-align: center;
  display: block;
  width: 30%;
}
</style>
