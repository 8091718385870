<template>
  <div class="faq" id="faq">
    <div class="section">
      <div class="container">
        <h1 class="faqheading">FAQ</h1>
        <div class="faqblock" v-for="faq in faqs" :key="faq.title">
          <h2 @click="faq.open = !faq.open" class="faqsuper">
            <span class="left">{{ faq.title }}</span
            ><span class="right">{{ faq.open ? "-" : "+" }}</span>
          </h2>
          <p
            v-show="faq.open == true"
            v-html="faq.question"
            class="faqtext"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      faqs: [
        {
          title: "WHAT IS AN NFT?",
          question: `An NFT stands for “Non-fungible token” and is a fancy way of saying
          it’s a unique, one of a kind digital item that users can buy, own, and
          trade. Some NFTs main function are to be digital art and look cool,
          some offer additional utility like exclusive access to websites or
          participation in an event, think of it like a rare piece of art that
          can also act as a “membership” card.`,
          open: false,
        },
        {
          title: "WHAT IS METAMASK?",
          question: ` Metamask is a crypto wallet that can store your Ethereum, and is
          needed to purchase and mint a Wrister. This will allow websites (that you authorize) access to your Metamask wallet for transactions.
          Having a wallet gives you an Ethereum address (i.e. 0xABCD….1234), this is where your NFT will be
          stored. Learn more about Metamask and how easy it is to use over here!
          (<a target="blank"class="link" href="https://metamask.io">https://metamask.io</a>). If you made a new account, you can now 
          purchase Ethereum directly through Metamask and use it to mint your Wrister by using the mint button at the top of the page when sales open. `,
          open: false,
        },
        {
          title: "WHERE DOES MY SKATER GO?",
          question: `As your Wicked Wristers skater is an NFT, they will live on the blockchain, with full ownership belonging to you!
          When you mint one fresh, or buy one on the secondary market, ownership will be transferred to your ethereum address, and into your wallet.
          To view your skater and any other NFT's in your wallet, you can visit sites such as Opensea, or view under the NFT tab of some wallets such as the Metamask mobile app.
          Eventually you will also be able to link your wallet to the Wicked Wristers site and view all your NFT skaters, trophies and awards.`,
          open: false,
        },
        {
          title: "TOKENOMICS?",
          question: `The total number of "skaters" (NFT's) will be limited to 2000, with a staged release as the hockey league seasons progress. The initial minting will be for 800 skaters, who will take part in the first 3 seasons. Following this, there will be 200 new skaters available for minting each season. Seasons are set to take place over a period of 1 month. Of the initial 800 skaters minted, there will be a presale available to the 100 "First Rounders", the early adopters, who will be able to mint 2 skaters each. There will also be a pre-mint of 12 skaters set aside for the creators of the project, and 10 for giveaways. Giving a total of 578 skaters available for the initial public sale. The total distribution will happen over a period of 9 months.`,
          open: false,
        },
        {
          title: "WHEN DOES MINTING BEGIN?",
          question:
            "Minting is set to begin on November 18, with the reveal of the skaters on November 20.",
          open: false,
        },
        {
          title: "HOW MUCH WILL THE MINTING COST?",
          question: "The minting cost will be 0.08 ETH per skater + gas fees.",
          open: false,
        },
        {
          title: "HOW MANY SKATERS CAN I MINT?",
          question:
            "Each wallet is limited to minting 2 skaters. We want a diverse group of individuals in the community and as many people as possible to be able to mint a skater directly.",
          open: false,
        },
        {
          title: "WHAT BLOCKCHAIN IS WICKED WRISTERS ON?",
          question: "Wicked Wristers skaters are minted on Ethereum.",
          open: false,
        },
        {
          title: "WHAT ARE THE FIRST ROUNDERS?",
          question:
            "The first rounders are the group of early adopters that will be eligible for the whitelisted pre-sale. Check in on the discord to see if there are any spots left!",
          open: false,
        },
        {
          title: "I HAVE MORE QUESTIONS.",
          question:
            "Join the Wicked Wristers discord and we will be happy to help with any questions you have.",
          open: false,
        },
      ],
    };
  },
};
</script>

<style>
.link:hover {
  color: #1DA1F2;
  font-size: 1em;
}

.faq {
  background-color: white;
  margin-bottom: 4%;
}

.faqheading {
  color: #1b112d !important;
  font-family: POPFUN, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 7.5em;
  text-align: center;
  letter-spacing: 9.07px;
}
.faqtext {
  color: white !important;
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1em;
  text-align: left;
  letter-spacing: 0.51px;
  line-height: 36px;
  margin-left: 5%;
  margin-right: 5%;
}
.faqsuper {
  color: white;
  font-family: POPFUN, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 97px;
  text-align: left;
  cursor: pointer;
}
.faqblock {
  background-color: #1b112d;
  padding: 2%;
  margin-top: 2%;
}

.right {
  float: right;
  -webkit-text-fill-color: white;
}
</style>