<template>
  <div class="msimulation" id="simulation">
    <div class="section">
      <img class="mgame-box" src="/img/WWPRODUCTBOXsingle.png" />
      <h1 class="msimulationheading">GAME SIM</h1>
      <img src="/img/SIM3.png" class="mbg-img">
      <div class="msimulationheading2">LIVE TEXT SIMULATION</div>
      <p class="msimulationtext">
        We are currently working on and developing a very intuitive and
        fun user-experience webapp that will run our simulation where YOUR
        nft's are the stars! This is the meat and potatoes of our utility
        for this project; this is just the game sim screen, but we will
        have a dashboard where you will find everything from current
        season schedule, hopping into an ongoing sim, all the way to current
        and past player stats along with transactions and legacy areas
        like the history books, hall of fame and trophy cabinets!
      </p>
      <p class="msimulationtext2">
        Each of the <strong class="has-text-white">32 TEAMS</strong> will
        have <strong class="has-text-white">3 GAMES</strong> simulated
        everyday spread throughout. This will result in
        <strong class="has-text-white">1 SEASON</strong> of
        <strong class="has-text-white">60 GAMES</strong> taking
        <strong class="has-text-white">1 MONTH</strong> in real time!
      </p>
      <p class="msimulationtext3">
        Every team is made up of  <strong class="has-text-white">25 SKATERS</strong> (your nfts)
      </p>
      <p class="msimulationtext4">
        • <strong class="has-text-white">12</strong> OFFENSIVE<br />
        • <strong class="has-text-white">6</strong> DEFENSIVE<br />
        • <strong class="has-text-white">2</strong> GOALIES<br />
        • <strong class="has-text-white">5</strong> BENCH<br />
      </p>
    </div>
  </div>
</template>


<script>
export default {
  name: "Simulation",
};
</script>

<style>

.msim-img {
  margin-bottom: 20%;

}

.msimulation {
  background-color: #7239D9;
  height: 925px;
}
.msimulationheading {
  color: white;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 2.2em;
  text-align: center;
  letter-spacing: 2px;
  margin-left: -30%;
  margin-right: -30%;
}
.msimulationheading2 {
  color: white;
  margin-top: -15%;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 2.2em;
  text-align: center;
  letter-spacing: 2px;
  text-shadow: black 1px 0 10px;
}
.mbg-img {
  display: flex;
  width: 300px;
  margin-left: auto;
  margin-right: auto;

}

.msimulationtext {
  font-family: proxima-nova, sans-serif;
  color: white !important;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.81px;
}
.msimulationtext2 {
  font-family: proxima-nova, sans-serif;
  color: white !important;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.81px;
  margin-top: 5%;
}
.msimulationtext3 {
  font-family: proxima-nova, sans-serif;
  color: white !important;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.81px;
  margin-top: 5%;
}
.msimulationtext4 {
  font-family: proxima-nova, sans-serif;
  color: white !important;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  line-height: 2;
  letter-spacing: 0.81px;
}

.mgame-box {
  margin-top: -65%;
  max-width: 40%;
}
</style>