<template>
  <div class="rarity" id="rarity">
    <div class="section" style="padding-bottom: 0">
      <div class="columns">
        <div class="column">
          <div class="mrarityheading">Generative Art</div>
          <div class="mraritytext">
            Your skaters are generated with 140 hairstyles, 140 faces and up to
            10 different traits. The individual assets that can be selected are
            weighted with different rarities and are able to produce over 1
            BILLION combinations
          </div>
        </div>
        <div class="column is-vcentered">
          <figure class="image is-226x227">
            <img src="/img/24frameanimationbig.gif" class="mrarityimg" />
          </figure>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="mrarityheading2">Dynamic NFT</div>
          <div class="mraritytext">
            Your NFT will be dynamic and updated once a month! Your players
            progression in the SIM can catapult your player to higher overalls
            therefore changing their background! Your jersey may update as well
            if you've been traded!
          </div>
        </div>
        <div class="column is-vcentered">
          <figure class="image is-256x256">
            <img src="/img/upgradegifnotextbig.gif" class="mrarityimg" />
          </figure>
        </div>
      </div>
      <p class="mbottomtext">
        YOUR SKATER'S BEARD AND ACCESSORIES ARE CURATED TO MAKE SURE IT WILL FIT
        YOUR SKATERS FACE SHAPE!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Rarity",
};
</script>

<style>
.rarity {
  background-color: #3a85ff;
  padding-top: 3%;
  padding-bottom: 3%;
}

.mrarityheading {
  color: white;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 4.5em;
  text-align: center;
  letter-spacing: 4.5px;
}

.mrarityheading2 {
  color: white;
  font-family: POPFUN;
  font-weight: 300;
  font-style: normal;
  font-size: 4.5em;
  text-align: center;
  letter-spacing: 4.5px;
}

.raritytitle {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5em;
  text-align: center;
}
.mraritytext {
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2em;
  text-align: center;
  padding-bottom: 3%;
  margin-top: -5%;
  line-height: 1.5;
}

.mbottomtext {
  color: white;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2em;
  padding-bottom: 5%;
  padding-left: 15%;
  padding-right: 15%;
}

.raritynumber {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 5.83px;
}

.mrarityimg {
  padding-bottom: 10%;
  max-width: 60%;
  margin: 0 auto;
}

.pfp {
  border-radius: 50%;
  width: 60%;
}
</style>